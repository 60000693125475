.w-fit {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .modal {
    @apply fixed top-0 left-0 w-full h-screen flex flex-col justify-center items-center;
    /*background-color: rgba(0, 0, 0, 0.2);*/
  }
  
  .modal-inner {
    @apply rounded-md relative w-fit p-4 pt-8;
  }
  
  /*# sourceMappingURL=SellInterface.css.map */
  
  .pu-container {
    background-color: #FCFCFB;
    font-family: Urbanist;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 55px;/* or 262% */
    letter-spacing: 0.075em;
    text-transform: uppercase;
    color: #0A3E62;
    padding:30px;
  }
  
  .pu-inner {
    text-align: center;
  }
  
  .buttons > button {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 30px;
    cursor: pointer;
    
  }
  
  .close-btn {
    background: transparent;
    font-family: Urbanist;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    float: right;/* or 262% */
    letter-spacing: 0.075em;
    text-transform: uppercase;
    color: #0A3E62;
    border: 1px solid #0A3E62;
    border-radius: 100px;
    
    cursor: pointer;
  }

  .label-top{
    top: 0;
    height: 130px;
    width: 220px;
    margin: 0;
    padding-top: 30px;
  } 
  
  .label-active {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 55px;/* or 157% */
    text-align: center;
    letter-spacing: 0.075em;
    text-transform: uppercase;
    color: #1A5883;
  }
  
  .label-disabled {
    background-color: #ECECEC;
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 55px;/* or 262% */
    text-align: center;
    letter-spacing: 0.075em;
    text-transform: uppercase;
    color: #5F5C6E;
    margin-top: -12px;
    margin-right: -5px;
    margin-left: -5px;
  }
  .label-left {
    float: left;
  }
  .label-right {
    float: right;
  }
  
  .i-info-round {
    width: 32px;
    height: 32px;
    cursor: pointer;
    margin-left: 20px;
  }

  .title-popup-buysell {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 55px;
    letter-spacing: 0.075em;
    text-transform: uppercase;
    color: #0A3C60;
    margin-top: 30px;
    height: 80px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
  
  .align-last-row-text {
    margin-top: 55px;
  }

  .border-right {
    border-right: 3px solid #1A5883;
    height: 90%;
  }

  
.quote-def {
    text-align: left;
    line-height: 40px;
  }
  
  .p2p-underline {
    border-bottom: 1px solid #1A5883
  }
  
  .p2p-quote-values {
    font-weight: bold;
  }
  
  .field-popup {
    text-align: left;
  }

  .cb-container {
    display: block;
    position: relative;
    padding-left: 35px;
    /*margin-bottom: 12px;*/
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .cb-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 13px;
    left: -25px;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  .cb-container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  .cb-container input:checked ~ .checkmark {
    background-color: #0A3C60;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .cb-container input:checked ~ .checkmark:after {
    display: block;
  }
  .cb-container .checkmark:after {
     top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }

  .grey-review {
    background-color: #dedede;
    margin-top: 20px;
    line-height: 30px;
  }

  .title-review {
    font-size: 25px;
  }