@tailwind components;
@tailwind utilities;

:root {
  --p-first: #0A3E62;
  --p-second: #1A5883;
  --p-third: #2E709D;
}

* {
  box-sizing: border-box;
}

html {
  min-height: 100%;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  margin: 0px;
  /*padding-left: 10px;
  padding-right: 10px;*/
  /*padding-top:36px;*/
  padding-top: 0px;
  padding-bottom: 0px;
  font-family: Jost;
  background: radial-gradient(50% 67.15% at 50% 50%, #5B7587 0%, #4B5C68 100%);
  min-height: 100vh; /*95vh;*/
  height: 100%;
  mix-blend-mode: inherit;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.87);

}

body.modal-open {
  height: 100vh;
  overflow: hidden;
  position: fixed;
}

#root {
  min-height: 100vh;
}

h1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 96px;
  line-height: 112px;
  letter-spacing: -1.5px;
  color: rgba(0, 0, 0, 0.87);
}

h2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 72px;
  letter-spacing: -0.5px;
  color: rgba(0, 0, 0, 0.87);
}

h3 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 56px;
  color: rgba(0, 0, 0, 0.87);
}

h4 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  color: rgba(0, 0, 0, 0.87);
}

.h7 {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: 1.25px;
  color: #000000;
}

.h8 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: -0.5px;
  color: rgba(0, 0, 0, 0.87);
}


a {
  text-decoration: none;
  color: inherit;
}

.page {
  min-height: 75vh;
}

/* SIMON CODe FOR PHONE DROPDOWN */

ul.country-list li:hover{
  background-color: #1890E3 !important;
  color: black;
}

ul.country-list li.highlight{
  background-color: #1890E3 !important;
  color: black;
}

/* FORM VALIDATION */

.error {
  border: solid 1px rgb(255, 207, 116) !important;
  background-color: rgba(46, 112, 157, 0.2) !important;
  
}

select.error option  {
  /*border: solid 1px red;*/
  color: white;
}

#DexxDialog > div > div {
  overflow-y: visible;
}

#DexxDialog > div > div > .MuiDialogContent-root {
  padding: 0;
}

.bg-spot-big {
  background: linear-gradient(98.24deg,rgba(10, 62, 98, 0.30) 0%, rgba(8, 30, 86, 0.45) 100%);
  height: 100vh;
  width: 70vw;
  position: fixed;
  z-index: -1;
  left: 10vw;
  rotate: -15deg;
  filter: blur(100px);
}

.bg-spot-small {
  background: linear-gradient(98.24deg, rgba(96, 119, 145, 0.45) 0%, rgba(10, 62, 98, 0.45) 100%);
  position: fixed;
  height: 40vw;
  width: 35vw;
  rotate: 22.15deg;
  z-index: -2;
  top: -10vw;
  left: -5vw;
  border-radius: 9999px;
  filter: blur(100px);
  mix-blend-mode: overlay;
}

.bg-radial {
  background: radial-gradient(50% 50% at 50% 50%, rgba(229, 223, 214, 0.39) 0%, rgba(9, 77, 126, 0) 100%);
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}